<template>
    <div class="card mb-5 mb-xl-10">
									<!--begin::Card header-->
									<div class="card-header card-header-stretch pb-0">
										<!--begin::Title-->
										<div class="card-title">
											<h2 class="m-0">Cüzdan</h2>
										</div>
										<!--end::Title-->
										<!--begin::Toolbar-->
										<div class="card-toolbar m-0">
											<!--begin::Tab nav-->
											<ul class="nav nav-stretch nav-line-tabs border-transparent" role="tablist">
												<!--begin::Tab item-->
												<li class="nav-item" role="presentation">
													<a id="kt_billing_creditcard_tab" class="nav-link fs-5 fw-bolder me-5 active" data-bs-toggle="tab" role="tab" href="#kt_billing_creditcard">Kredi Kartı</a>
												</li>
												<!--end::Tab item-->
												<!--begin::Tab item-->
												<li class="nav-item" role="presentation">
													<a id="kt_billing_Iban_tab" class="nav-link fs-5 fw-bolder" data-bs-toggle="tab" role="tab" href="#kt_billing_Iban">Iban</a>
												</li>
												<!--end::Tab item-->
											</ul>
											<!--end::Tab nav-->
										</div>
										<!--end::Toolbar-->
									</div>
									<!--end::Card header-->
									<!--begin::Tab content-->
									<div id="kt_billing_payment_tab_content" class="card-body tab-content">
										<!--begin::Tab panel-->
										<div id="kt_billing_creditcard" class="tab-pane fade show active" role="tabpanel">
											<!--begin::Title-->
											<h3 class="mb-5">Kayıtlı Kredi Kartlarım</h3>
											<!--end::Title-->
											<!--begin::Row-->
											<div class="row gx-9 gy-6">
												<!--begin::Col-->
												<div class="col-xl-6">
													<!--begin::Card-->
													<div class="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6">
														<!--begin::Info-->
														<div class="d-flex flex-column py-2">
															<!--begin::Owner-->
															<div class="d-flex align-items-center fs-4 fw-bolder mb-5">Marcus Morris 
															<span class="badge badge-light-success fs-7 ms-2">Primary</span></div>
															<!--end::Owner-->
															<!--begin::Wrapper-->
															<div class="d-flex align-items-center">
																<!--begin::Icon-->
																<img src="https://preview.keenthemes.com/metronic8/demo1/assets/media/svg/card-logos/visa.svg" alt="" class="me-4">
																<!--end::Icon-->
																<!--begin::Details-->
																<div>
																	<div class="fs-4 fw-bolder">Visa **** 1679</div>
																	<div class="fs-6 fw-bold text-gray-400">Card expires at 09/24</div>
																</div>
																<!--end::Details-->
															</div>
															<!--end::Wrapper-->
														</div>
														<!--end::Info-->
														<!--begin::Actions-->
														<div class="d-flex align-items-center py-2">
															<button type="reset" class="btn btn-sm btn-light btn-active-light-primary me-3">Delete</button>
															<button class="btn btn-sm btn-light btn-active-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_new_card">Edit</button>
														</div>
														<!--end::Actions-->
													</div>
													<!--end::Card-->
												</div>
												<!--end::Col-->
												<!--begin::Col-->
												<div class="col-xl-6">
													<!--begin::Card-->
													<div class="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6">
														<!--begin::Info-->
														<div class="d-flex flex-column py-2">
															<!--begin::Owner-->
															<div class="d-flex align-items-center fs-4 fw-bolder mb-5">Jacob Holder</div>
															<!--end::Owner-->
															<!--begin::Wrapper-->
															<div class="d-flex align-items-center">
																<!--begin::Icon-->
																<img src="https://preview.keenthemes.com/metronic8/demo1/assets/media/svg/card-logos/mastercard.svg" alt="" class="me-4">
																<!--end::Icon-->
																<!--begin::Details-->
																<div>
																	<div class="fs-4 fw-bolder">Mastercard **** 2040</div>
																	<div class="fs-6 fw-bold text-gray-400">Card expires at 10/22</div>
																</div>
																<!--end::Details-->
															</div>
															<!--end::Wrapper-->
														</div>
														<!--end::Info-->
														<!--begin::Actions-->
														<div class="d-flex align-items-center py-2">
															<button type="reset" class="btn btn-sm btn-light btn-active-light-primary me-3">Delete</button>
															<button class="btn btn-sm btn-light btn-active-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_new_card">Edit</button>
														</div>
														<!--end::Actions-->
													</div>
													<!--end::Card-->
												</div>
												<!--end::Col-->
												<!--begin::Col-->
												<div class="col-xl-6">
													<!--begin::Card-->
													<div class="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6">
														<!--begin::Info-->
														<div class="d-flex flex-column py-2">
															<!--begin::Owner-->
															<div class="d-flex align-items-center fs-4 fw-bolder mb-5">Jhon Larson</div>
															<!--end::Owner-->
															<!--begin::Wrapper-->
															<div class="d-flex align-items-center">
																<!--begin::Icon-->
																<img src="https://preview.keenthemes.com/metronic8/demo1/assets/media/svg/card-logos/mastercard.svg" alt="" class="me-4">
																<!--end::Icon-->
																<!--begin::Details-->
																<div>
																	<div class="fs-4 fw-bolder">Mastercard **** 1290</div>
																	<div class="fs-6 fw-bold text-gray-400">Card expires at 03/23</div>
																</div>
																<!--end::Details-->
															</div>
															<!--end::Wrapper-->
														</div>
														<!--end::Info-->
														<!--begin::Actions-->
														<div class="d-flex align-items-center py-2">
															<button type="reset" class="btn btn-sm btn-light btn-active-light-primary me-3">Delete</button>
															<button class="btn btn-sm btn-light btn-active-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_new_card">Edit</button>
														</div>
														<!--end::Actions-->
													</div>
													<!--end::Card-->
												</div>
												<!--end::Col-->
												<!--begin::Col-->
												<div class="col-xl-6">
													<!--begin::Notice-->
													<div class="notice d-flex bg-light-primary rounded border-primary border border-dashed h-lg-100 p-6">
														<!--begin::Wrapper-->
														<div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
															<!--begin::Content-->
															<div class="mb-3 mb-md-0 fw-bold">
																<h4 class="text-gray-900 fw-bolder">Important Note!</h4>
																<div class="fs-6 text-gray-700 pe-7">Please carefully read 
																adding your new payment card</div>
															</div>
															<!--end::Content-->
															<!--begin::Action-->
															<a href="#" class="btn btn-primary px-6 align-self-center text-nowrap" data-bs-toggle="modal" data-bs-target="#kt_modal_new_card">Kart Ekle</a>
															<!--end::Action-->
														</div>
														<!--end::Wrapper-->
													</div>
													<!--end::Notice-->
												</div>
												<!--end::Col-->
											</div>
											<!--end::Row-->
										</div>
										<!--end::Tab panel-->
										<!--begin::Tab panel-->
										<div id="kt_billing_Iban" class="tab-pane fade" role="tabpanel" aria-labelledby="kt_billing_Iban_tab">
                                            <h3 class="mb-5"> Kayıtlı Iban Hesaplarım </h3> 
                                            <div class="row gx-9 gy-6">
                                                 <div class="col-xl-6">
													<!--begin::Notice-->
													<div class="notice d-flex bg-light-primary rounded border-primary border border-dashed h-lg-100 p-6">
														<!--begin::Wrapper-->
														<div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
															<!--begin::Content-->
															<div class="mb-3 mb-md-0 fw-bold">
																<h4 class="text-gray-900 fw-bolder">Important Note!</h4>
																<div class="fs-6 text-gray-700 pe-7">Please carefully read 
																adding your new payment card</div>
															</div>
															<!--end::Content-->
															<!--begin::Action-->
															<a href="#" class="btn btn-primary px-6 align-self-center text-nowrap" data-bs-toggle="modal" data-bs-target="#kt_modal_new_card">Iban Ekle</a>
															<!--end::Action-->
														</div>
														<!--end::Wrapper-->
													</div>
													<!--end::Notice-->
												</div>
                                                </div>
										</div>
										<!--end::Tab panel-->
									</div>
									<!--end::Tab content-->
								</div>
</template>